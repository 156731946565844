import React from "react";
import styled from "styled-components";
import { MD_HEADER_CLASS } from "../../../../constants";
import { toIdFormat } from "../../../../utils";
import { H2 } from "../../../common";
import { commonHeaderStyles } from "../common";

const ContentH2 = styled(H2)`
  ${commonHeaderStyles}
`;

export const MarkDownH2 = ({ children }) => {
  return (
    <ContentH2 id={toIdFormat(children)} className={MD_HEADER_CLASS}>
      {children}
    </ContentH2>
  );
};
