import React from "react";
import { Copyright } from "./copyright";
import { ExternalLinks } from "./external";
import { FooterBottomWrapper } from "./wrapper";

export const FooterBottom = () => {
  return (
    <FooterBottomWrapper>
      <Copyright />
      <ExternalLinks />
    </FooterBottomWrapper>
  );
};
