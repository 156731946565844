import { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import { getIncrementedView } from "../db/service";

export const useFetchAndIncrementView = () => {
  const { pathname } = useLocation();
  const [data, setData] = useState(0);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!loading) return;
    const fetchData = async () => {
      const [ok, viewCount] = await getIncrementedView(pathname);
      setData(viewCount);
      setSuccess(ok);
      setLoading(false);
    };
    fetchData();
  }, [loading, pathname]);

  return [loading || !success, data];
};
