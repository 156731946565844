import styled, { css } from "styled-components";
import { CommonTextStyle } from "./common";

export const SParagraph = css`
  ${CommonTextStyle}

  margin-top: 5px;
  line-height: ${({ theme }) => theme.lineHeight.xl};
  padding-bottom: 15px;

  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.colors.textColor};

  word-break: normal; /* p 태그의 width를 넘어가는 경우 다음 줄에 내용 표시. h1 등에서는 불필요 */
`;

export const Paragraph = styled.p`
  ${SParagraph}
`;
