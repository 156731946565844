import { baseStyles, preventUserSelect, transitionStyles } from "./base-styles";
import { DARK, LIGHT, widthSize, device, heightConstants } from "../constants";

const defaultTheme = {
  baseStyles,
  preventUserSelect,
  transitionStyles,
  widthSize,
  device,
  heightSize: heightConstants,

  font: {
    // main: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, sans-serif, Tahoma, Geneva, Verdana",
    // main: "sans-serif, ui-sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji, Segoe UI Symbol",
    main: "ui-sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji, Segoe UI Symbol",
    monospace: "Ubuntu Mono, monospace",
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },
  fontSize: {
    xs: "0.875rem",
    sm: "1.0rem",
    base: "1.1rem",
    lg: "1.25rem",
    huge: "1.35rem",
    xl: "1.5rem",
    xl2: "1.75rem",
    xl3: "2rem",
    xl4: "2.25rem",
    xl5: "3rem",
    xl6: "4rem",
    xl7: "6rem",
    xl8: "8rem",
    xl9: "12rem",
    xl10: "16rem",
  },
  lineHeight: {
    small: 0.5,
    normal: 1.0,
    big: 1.25,
    lg: 1.5,
    xl: 1.75,
  },
  letterSpacing: {
    normal: 1.0,
    wide: 1.5,
  },
};

export const lightTheme = {
  ...defaultTheme,
  name: LIGHT,
  colors: {
    backgroundColor: "rgb(246, 246, 246)",
    backgroundColorTranslucent: "rgba(246, 246, 246, 0.95)",

    textColor: "rgb(0, 0, 0)",
    highlightColor: "rgb(77, 77, 255)",
    minimumHighlight: "rgb(211, 204, 253)",
    thickHighlightColor: "rgb(50, 50, 255)",
    markdownStrongTagHighlight: "rgb(73, 73, 255)",

    gray: "rgb(90, 90, 90)",
    grayThick: "rgb(90, 90, 90)",
    grayBorder: "rgba(90, 90, 90, 0.3)",
    markdownInfoSectionBox: "rgba(90, 90, 90, 0.15)",

    grayBoxTranslucent: "rgba(90, 90, 90, 0.2)",
    grayTranslucent: "rgba(90, 90, 90, 0.15)",

    boxShadow: "0px 5px 20px 2px rgba(90, 90, 111, 0.2)",
    boxShadowBottomOnly: "0px 15px 20px -20px rgba(90, 90, 111, 0.2)",
  },
};

export const darkTheme = {
  ...defaultTheme,
  name: DARK,
  colors: {
    backgroundColor: "rgb(37, 37, 37)",
    backgroundColorTranslucent: "rgba(37, 37, 37, 0.95)",

    textColor: "rgb(246, 246, 246)",
    highlightColor: "rgb(104, 104, 250)",
    minimumHighlight: "rgb(140, 140, 255)",
    thickHighlightColor: "rgb(87, 87, 255)",
    markdownStrongTagHighlight: "rgb(145, 145, 253)",

    gray: "rgb(71, 71, 71)",
    grayThick: "rgb(116, 116, 116)",
    grayBorder: "rgba(212, 212, 212, 0.3)",
    markdownInfoSectionBox: "rgba(116, 116, 116, 0.25)",

    grayBoxTranslucent: "rgba(212, 212, 212, 0.3)",
    grayTranslucent: "rgba(116, 116, 116, 0.3)",

    boxShadow: "0px 5px 20px 2px rgba(124, 115, 115, 0.2)",
    boxShadowBottomOnly: "0px 15px 20px -20px rgba(124, 115, 115, 0.2)",
  },
};
