import styled, { css } from "styled-components";
import { H3 } from "../../common";

export const commonHeaderStyles = css`
  padding: 20px 0 15px 0;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: ${({ theme }) => theme.lineHeight.xl};
`;

export const Caption = styled(H3)`
  padding: 0 0 10px 0;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: ${({ theme }) => theme.lineHeight.xl};
`;
