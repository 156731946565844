import React from "react";
import styled from "styled-components";
import { Caption } from "../common";

export const SReferenceText = styled(Caption)`
  padding-top: 10px;
  padding-left: 10px;
`;

export const ReferenceText = () => <SReferenceText> 참고자료</SReferenceText>;

export const OfficialDoc = () => <SReferenceText>원문</SReferenceText>;
