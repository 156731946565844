import React from "react";
import styled from "styled-components";
import { H3 } from "../../common";
import { MarkDownParagraph } from "./text";

const Wrapper = styled.div`
  padding: 20px 10px;
  margin-bottom: 25px;

  background-color: ${({ theme }) => theme.colors.markdownInfoSectionBox};
  border-radius: 10px;
`;

const Title = styled(H3)`
  text-align: center;
`;

const Content = styled(MarkDownParagraph).attrs({ as: "div" })`
  padding: 3px 10px 0 10px;

  & > p:last-child {
    padding-bottom: 0px;
  }
`;

export const SubSection = ({ title, children }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Wrapper>
  );
};
