import React from "react";
import { LinkStyle, SParagraph } from "../../common";
import styled from "styled-components";

export const SAnchor = styled.a`
  ${SParagraph}
  ${LinkStyle}
`;

export const MarkdownAnchor = ({ href, children }) => {
  return (
    <SAnchor href={href} target="_blank">
      {children}
    </SAnchor>
  );
};
