import styled from "styled-components";

export const LinkContainer = styled.span`
  padding: 5px;
  margin: 5px;

  border-radius: 50%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayBoxTranslucent};
  }
`;
