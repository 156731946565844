import { css } from "styled-components";
import { CommonTextStyle } from "./common";

export const LinkStyle = css`
  ${CommonTextStyle}
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-underline-position: under;

  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
  }
`;
