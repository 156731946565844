import { css } from "styled-components";

export const CommonTextStyle = css`
  font-family: ${({ theme }) => theme.font.main};
`;

export const headerCommonStyle = css`
  ${CommonTextStyle}
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
