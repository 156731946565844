import React from "react";
import styled from "styled-components";
import { MD_HEADER_CLASS } from "../../../../constants";
import { toIdFormat } from "../../../../utils";
import { H1 } from "../../../common";
import { commonHeaderStyles } from "../common";

const ContentH1 = styled(H1)`
  ${commonHeaderStyles}
`;

export const MarkDownH1 = ({ children }) => {
  return (
    <ContentH1 id={toIdFormat(children)} className={MD_HEADER_CLASS}>
      {children}
    </ContentH1>
  );
};
