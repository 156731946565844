import styled from "styled-components";
import { Paragraph } from "../../../common";
import { css } from "styled-components";

export const emStyle = css`
  font-style: italic;
  word-break: normal;
`;

export const MarkDownItalic = styled(Paragraph).attrs({ as: "em" })`
  ${emStyle}
`;
