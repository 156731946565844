import React from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { useStaticQuery, graphql } from "gatsby";
import { getOpenGraphData } from "./open-graph";
import { metaTags } from "./meta";
import { twitter } from "./twitter";
import { linkTags } from "./link";

// component for overriding default configuration in gatsby-config.js // Reference: https://www.gatsbyjs.com/plugins/gatsby-plugin-next-seo/?=gatsby-plugin-next-seo

const SeoHelmet = ({
  title,
  description,
  mainImageSrc,
  articleInfo,
  pathname,
}) => {
  const { site } = useStaticQuery(query);

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    siteUrlNoTrailingSlash,
    defaultImage,
    author,
  } = site.siteMetadata;

  const seo = {
    siteName: defaultTitle,
    title: title || defaultTitle,
    description: description || defaultDescription,
    imageUrl: `${siteUrlNoTrailingSlash}${mainImageSrc || defaultImage}`,
    pageUrl: pathname ? `${siteUrlNoTrailingSlash}${pathname}` : siteUrl,
    article: articleInfo || false,
    author,
  };

  return (
    <GatsbySeo
      title={seo.title}
      titleTemplate={title ? titleTemplate : seo.title}
      description={seo.description}
      canonical={seo.pageUrl}
      openGraph={getOpenGraphData(seo)}
      metaTags={metaTags}
      linkTags={linkTags}
      twitter={twitter}
    />
  );
};

export default SeoHelmet;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        siteUrlNoTrailingSlash
        defaultImage: image
        author
      }
    }
  }
`;
