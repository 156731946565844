import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const getDocument = async (docRef) => {
  const document = await docRef.get();

  const ok = document.exists;
  const data = document.data();

  return [ok, data];
};

const incrementViewByDocument = async (docRef, updatedViewCount) =>
  docRef.set({ views: updatedViewCount }); // set(): doc 없으면 생성 | update() : doc 없으면 요청 실패

export const getIncrementedView = async (pathname) => {
  let viewCount = 0;
  if (!pathname) return [false, viewCount];

  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: process.env.FB_API_KEY,
      authDomain: process.env.FB_AUTH_DOMAIN,
      projectId: process.env.FB_PROJECT_ID,
      storageBucket: process.env.FB_STORAGE_BUCKET,
      messagingSenderId: process.env.FB_MESSAGING_SENDER_ID,
      appId: process.env.FB_APP_ID,
    });
  }

  if (firebase) {
    const docRef = firebase.firestore().doc(`/blog/view${pathname}`);
    const [docExists, data] = await getDocument(docRef);
    if (docExists) {
      viewCount = data.views;
    }
    viewCount++;
    incrementViewByDocument(docRef, viewCount);

    return [true, viewCount];
  }
};
