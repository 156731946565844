import React from "react";
import { Svg } from "./svg";

export const BuyMeACoffeeThinIcon = ({ size }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    viewBox="0 0 300.000000 300.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M1290 2693 c-248 -23 -361 -53 -409 -108 -17 -20 -31 -47 -31 -61 0
   -95 123 -149 404 -176 121 -11 446 -4 536 12 86 15 115 66 65 116 -23 23 -28
   24 -97 17 -120 -13 -556 -9 -620 6 -32 7 -58 16 -58 21 0 38 554 50 738 16 57
   -11 113 -27 124 -35 19 -16 57 -156 58 -213 0 -33 -1 -33 -160 -49 -243 -23
   -744 -6 -905 31 -101 24 -186 -5 -219 -74 -25 -53 -50 -189 -42 -231 9 -45 66
   -110 109 -124 59 -19 321 -50 516 -62 149 -9 242 -9 405 0 116 6 221 14 233
   17 13 4 28 -1 40 -13 22 -22 27 46 -51 -724 -59 -570 -57 -563 -131 -599 -64
   -31 -198 -42 -381 -32 -140 8 -166 12 -202 32 -48 27 -66 51 -81 106 -9 34
   -51 418 -107 969 -13 136 -19 163 -37 183 -28 30 -73 26 -98 -8 -17 -24 -16
   -42 42 -600 33 -316 64 -591 70 -611 27 -104 128 -171 284 -189 132 -15 388
   -12 467 4 80 17 116 35 169 83 42 38 71 86 83 137 4 16 36 308 71 649 44 435
   67 623 77 632 7 6 33 17 57 24 53 15 96 58 112 112 10 30 9 55 -1 112 -22 130
   -45 167 -127 203 -57 26 -56 23 -78 153 -20 119 -34 152 -78 185 -73 56 -333
   98 -589 94 -68 0 -139 -3 -158 -5z m-241 -577 c250 -41 630 -39 939 5 l154 22
   20 -21 c13 -13 22 -38 26 -70 5 -60 -14 -86 -74 -95 -323 -50 -758 -57 -1079
   -18 -104 13 -198 28 -209 33 -15 8 -18 19 -14 73 2 35 10 71 18 80 16 19 56
   18 219 -9z"
      />
    </g>
  </Svg>
);

export const BuyMeACoffeeSolidIcon = ({ size }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    viewBox="0 0 300.000000 300.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M1250 2643 c-248 -23 -361 -53 -409 -108 -17 -20 -31 -47 -31 -61 0
-95 123 -149 404 -176 121 -11 446 -4 536 12 86 15 115 66 65 116 -23 23 -28
24 -97 17 -120 -13 -556 -9 -620 6 -32 7 -58 16 -58 21 0 38 554 50 738 16 57
-11 113 -27 124 -35 19 -16 57 -156 58 -213 0 -33 -1 -33 -160 -49 -243 -23
-744 -6 -905 31 -101 24 -186 -5 -219 -74 -25 -53 -50 -189 -42 -231 9 -45 66
-110 109 -124 59 -19 321 -50 516 -62 149 -9 242 -9 405 0 116 6 220 14 231
17 11 3 29 -1 40 -9 20 -15 22 -54 9 -139 l-5 -36 -37 15 c-54 22 -202 14
-259 -15 -23 -12 -48 -22 -54 -22 -6 0 -30 -8 -53 -19 -124 -57 -281 -92 -381
-86 -80 5 -146 17 -159 30 -7 8 -16 50 -20 97 -5 65 -11 88 -29 106 -28 30
-73 26 -98 -8 -17 -24 -16 -42 42 -600 33 -316 64 -591 70 -611 27 -104 128
-171 284 -189 132 -15 388 -12 467 4 80 17 116 35 169 83 42 38 71 86 83 137
4 16 36 308 71 649 44 435 67 623 77 632 7 6 33 17 57 24 53 15 96 58 112 112
10 30 9 55 -1 112 -22 130 -45 167 -127 203 -57 26 -56 23 -78 153 -20 119
-34 152 -78 185 -73 56 -333 98 -589 94 -68 0 -139 -3 -158 -5z m-241 -577
c250 -41 630 -39 939 5 l154 22 20 -21 c13 -13 22 -38 26 -70 5 -60 -14 -86
-74 -95 -323 -50 -758 -57 -1079 -18 -104 13 -198 28 -209 33 -15 8 -18 19
-14 73 2 35 10 71 18 80 16 19 56 18 219 -9z"
      />
    </g>
  </Svg>
);
