import styled from "styled-components";

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 100%;

  margin: auto;
  max-width: ${({ theme }) => theme.widthSize.maxScreenWidth};

  padding: 20px 30px 30px 30px;
`;
