export const metaTags = [
  {
    name: "google-site-verification",
    content: "FZO_JFE5ElpMMZyfJjjmC6rUinPqI4zJces2QQL11G0",
  },
  {
    name: "naver-site-verification",
    content: "4e8b0102ce1d75207222bc819afeff829b136816",
  },
  {
    name: "NaverBot",
    content: "All",
  },
  {
    name: "NaverBot",
    content: "index,follow",
  },
  {
    name: "Yeti",
    content: "All",
  },
  {
    name: "Yeti",
    content: "index,follow",
  },
  // Google AdSense verification
  {
    name: "google-adsense-account",
    content: "ca-pub-1809151679128494",
  },
];
