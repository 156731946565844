import styled from "styled-components";

export const Sup = styled.sup`
  cursor: pointer;
  vertical-align: super;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.markdownStrongTagHighlight};

  /* text-decoration: underline; */

  &:hover {
    color: ${({ theme }) => theme.colors.thickHighlightColor};
    /* text-decoration: none; */
  }
`;
