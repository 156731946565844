import React from "react";
import { scrollToByElementId } from "../../../../utils";
import { ContentContainer } from "./content-container";
import { FooterSectionCaption } from "./footer-container";
import { FootNoteContainer } from "./footnote-container";
import { FootNoteInfo } from "./info";
import { IdNumBox } from "./num-box";
import { IdNumContainer } from "./num-container";
import { Sup } from "./sup";

const topId = (num) => `footnote-${num}`;
const bottomId = (num) => `footnote-info-${num}`;

export const TopFootNote = ({ num }) => {
  return (
    <Sup
      id={topId(num)}
      onClick={() => scrollToByElementId("#" + bottomId(num))}
    >
      {num}
    </Sup>
  );
};

export const BottomFootNote = ({ num, children }) => {
  return (
    <FootNoteInfo id={bottomId(num)}>
      <IdNumContainer>
        <IdNumBox onClick={() => scrollToByElementId("#" + topId(num))}>
          {num}
        </IdNumBox>
      </IdNumContainer>
      <ContentContainer>{children}</ContentContainer>
    </FootNoteInfo>
  );
};

export const FootNoteSection = ({ children }) => {
  return (
    <FootNoteContainer>
      <FooterSectionCaption>주석</FooterSectionCaption>
      {children}
    </FootNoteContainer>
  );
};
