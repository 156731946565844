import React from "react";
import styled from "styled-components";
import { MD_HEADER_CLASS } from "../../../../constants";
import { toIdFormat } from "../../../../utils";
import { H3 } from "../../../common";
import { commonHeaderStyles } from "../common";

const ContentH3 = styled(H3)`
  ${commonHeaderStyles}
  padding: 10px 0;
`;

export const MarkDownH3 = ({ children }) => {
  return (
    <ContentH3 id={toIdFormat(children)} className={MD_HEADER_CLASS}>
      {children}
    </ContentH3>
  );
};
