import { DICTIONARY_BREAKPOINTS, LANGUAGE } from "../../constants";
import { buildTagCountInfos } from "./count";

const getLanguageSortUtils = (lang) => {
  if (lang !== LANGUAGE.KO && lang !== LANGUAGE.EN) {
    throw new Error(
      `Only ${LANGUAGE.KO} or ${LANGUAGE.EN} are valid language argument`
    );
  }

  const breakPoints = [0]
    .concat(
      DICTIONARY_BREAKPOINTS[lang].breakPoints.map((korStart) =>
        korStart.charCodeAt()
      )
    )
    .concat([99999999999]);

  const divisionList = [];

  DICTIONARY_BREAKPOINTS[lang].caption.forEach((caption) => {
    divisionList.push({ data: [], caption });
  });

  return [breakPoints, divisionList];
};

const buildLanguageSortedTagList = (countDict, validTagInfoDict, lang) => {
  const [breakPoints, divisionList] = getLanguageSortUtils(lang);

  const arrayList = Object.keys(countDict).map((key) => [
    key,
    validTagInfoDict[key][lang],
    countDict[key],
  ]);

  arrayList.sort((cur, next) => {
    if (cur[1] > next[1]) return 1;
    return -1;
  });

  arrayList.forEach((infos) => {
    for (let idx = 0; ; idx++) {
      const encoding = infos[1].charCodeAt();
      if (breakPoints[idx] <= encoding && encoding < breakPoints[idx + 1]) {
        divisionList[idx].data.push({
          ...validTagInfoDict[infos[0]],
          count: infos[2],
        });
        break;
      }
    }
  });

  const first = divisionList.shift();
  const last = divisionList.pop();
  divisionList.concat(first).concat(last);

  return divisionList.concat(first).concat(last);
};

export const getTagLanguageInfoDictionary = (tagsList, lang) => {
  const [tagCountInfos, validTagInfoDict] = buildTagCountInfos(tagsList);
  return buildLanguageSortedTagList(tagCountInfos, validTagInfoDict, lang);
};
