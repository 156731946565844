import { calculateHeaderPositionY, getOutsideBlogPostPositionY } from ".";
import { MD_HEADER_CLASS } from "../../constants";

export const getRootHeaderLevel = (headerTags) => {
  const headerTargets = ["H1", "H2", "H3", "H4"];
  for (const idx in headerTargets) {
    if (!headerTags.includes(headerTargets[idx])) continue;
    return parseInt(idx) + 1;
  }
};

export const getMdxHeaderList = () =>
  Array.prototype.slice.call(document.getElementsByClassName(MD_HEADER_CLASS));

export const getHeaderPositionList = (mdxList) =>
  mdxList
    .map((element) => calculateHeaderPositionY(element.id) - 50) // 경계선 때문에 조금 높게 설정해야 영역에 제대로 잡힘
    .concat([getOutsideBlogPostPositionY()]);

export const getCurrentPositionList = (
  headerPositionList,
  listLength,
  currentHeight
) => {
  const newPositionList = Array(listLength).fill(false);
  for (let idx = 0; idx < listLength; idx++) {
    if (
      currentHeight < headerPositionList[idx] ||
      headerPositionList[idx + 1] < currentHeight
    )
      continue;
    newPositionList[idx] = true;
    break;
  }
  return newPositionList;
};
