import * as React from "react";
// import styled from "styled-components";
import { CopyrightContainer } from "./container";
import { CopyrightWrapper } from "./wrapper";

// export const Link = styled.a`
//   color: ${({ theme }) => theme.colors.highlightColor};
// `;

export const Copyright = () => {
  return (
    <CopyrightWrapper>
      <CopyrightContainer>© 2022 Jeong Jinwoo.</CopyrightContainer>
      <CopyrightContainer>
        All rights reserved.
        {/* Built with{" "}
        <Link href="https://www.gatsbyjs.com/" target="_blank">
          Gatsby
        </Link> */}
      </CopyrightContainer>
    </CopyrightWrapper>
  );
};
