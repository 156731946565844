import React, { useEffect } from 'react'

const dataAdClient = "ca-pub-1809151679128494";

const loadScript = () => {
  const script = document.createElement("script");
  // async 옵션을 붙인 것처럼 동작하는 것이 디폴트
  script.id = "adsense-script-async";
  script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${dataAdClient}`;
  script.crossorigin = "anonymous";
  document.head.appendChild(script);
};

export const DisplayAds = ({ dataAdSlot, isFixedSize, style }) => {
  if (document.getElementById("adsense-script-async") === null) {
    loadScript();
  }

  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        adsbygoogle.push({})
      } catch (e) {
        console.error(e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])

  if (isFixedSize || style !== undefined) {
    return (
      <div align="center">
        <ins
          className='adsbygoogle'
          style={style}
          data-ad-client={dataAdClient} // 사이트별 발급
          data-ad-slot={dataAdSlot} // 애드별 발급
        ></ins>
      </div>
    )
  }
  return (
    <div align="center">
      <ins
        className='adsbygoogle'
        style={{ display: 'block' }}
        data-ad-client={dataAdClient} // 사이트별 발급
        data-ad-slot={dataAdSlot} // 애드별 발급
        data-ad-format='auto' // 반응형 애드
        data-full-width-responsive='true' // 반응형 애드
      ></ins>
    </div>
  )
}
