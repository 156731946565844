import { getValidTagInfoDictionary } from "./common";

const validateTagDictionary = (tagDictionary, validTagInfoDictionary) =>
  Object.keys(tagDictionary).forEach((targetTag) => {
    if (!validTagInfoDictionary.hasOwnProperty(targetTag)) {
      throw new Error(
        `${targetTag} is not a valid tag. Add it in the dictionary!`
      );
    }
  });

export const buildTagCountInfos = (tagsList) => {
  const validTagInfoDictionary = getValidTagInfoDictionary();
  const tagCountInfos = {};

  for (const tagsIdx in tagsList) {
    const tags = tagsList[tagsIdx];

    for (const idx in tags) {
      const curTag = tags[idx];

      let exists = false;

      for (const tagName in tagCountInfos) {
        if (tagName !== curTag) continue;

        tagCountInfos[curTag] += 1;
        exists = true;
      }

      if (exists) continue;

      tagCountInfos[curTag] = 1;
    }
  }

  validateTagDictionary(tagCountInfos, validTagInfoDictionary);

  return [tagCountInfos, validTagInfoDictionary];
};

const buildCountSortedTagList = (countDict, validTagInfoDict) => {
  const arrayList = Object.keys(countDict).map((key) => [key, countDict[key]]);

  arrayList.sort((cur, next) => {
    if (cur[1] > next[1]) return -1;
    if (cur[1] < next[1]) return 1;

    if (validTagInfoDict[cur[0]].ko > validTagInfoDict[next[0]].ko) {
      return 1;
    }
    return -1;
  });

  return arrayList.map((array) => {
    return {
      ...validTagInfoDict[array[0]],
      count: array[1],
    };
  });
};

export const getTagFullInfoDictionary = (tagsList) => {
  const [tagCountInfos, validTagInfoDict] = buildTagCountInfos(tagsList);
  return buildCountSortedTagList(tagCountInfos, validTagInfoDict);
};
