import { Link } from "gatsby";
import styled from "styled-components";

export const NavigationLink = styled(Link)`
  color: ${({ theme }) => theme.colors.textColor};
  font-size: ${({ theme }) => theme.fontSize.lg};

  &:hover {
    text-decoration: underline;
    text-underline-position: under;
    color: ${({ theme }) => theme.colors.highlightColor};
  }
`;
