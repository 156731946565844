import styled from "styled-components";
import { ListStyle, ListWrapperStyle } from "../../common";
import { strongStyle, emStyle, InlineCodeStyle } from "./text";

export const MarkdownUl = styled.ul`
  ${ListWrapperStyle}

  li {
    ${ListStyle}
    list-style-type: square;

    strong {
      ${strongStyle}
    }
    em {
      ${emStyle}
    }
    code {
      ${InlineCodeStyle}
    }
  }
`;

export const MarkdownOl = styled.ol`
  ${ListWrapperStyle}

  li {
    ${ListStyle}
    list-style-type: decimal;

    strong {
      ${strongStyle}
    }
    em {
      ${emStyle}
    }
    code {
      ${InlineCodeStyle}
    }
  }
`;
