import * as React from "react";
import {
  GithubSolidIcon as GithubIcon,
  MailIconOutline as MailIcon,
  BuyMeACoffeeSolidIcon as BuyMeACoffeeIcon,
  RssIcon,
} from "../../../common";
import { LinkContainer } from "./container";
import { ExternalLink } from "./link";
import { ExternalLinksWrapper } from "./wrapper";
import {
  OPEN_RSS,
  SEND_EMAIL,
  OPEN_BUY_ME_A_COFFEE,
  OPEN_GITHUB,
} from "../../../../constants";

export const ExternalLinks = () => {
  return (
    <ExternalLinksWrapper>
      <LinkContainer>
        <ExternalLink
          href="https://github.com/bugoverdose"
          target="_blank"
          title={OPEN_GITHUB}
        >
          <GithubIcon size={30} />
        </ExternalLink>
      </LinkContainer>
      <LinkContainer>
        <ExternalLink href="mailto:jwjeong96@gmail.com" title={SEND_EMAIL}>
          <MailIcon size={30} />
        </ExternalLink>
      </LinkContainer>
      <LinkContainer>
        <ExternalLink
          href="https://www.buymeacoffee.com/bugoverdose"
          target="_blank"
          title={OPEN_BUY_ME_A_COFFEE}
        >
          <BuyMeACoffeeIcon size={30} />
        </ExternalLink>
      </LinkContainer>
      <LinkContainer>
        <ExternalLink href="/rss.xml" target="_blank" title={OPEN_RSS}>
          <RssIcon size={30} />
        </ExternalLink>
      </LinkContainer>
    </ExternalLinksWrapper>
  );
};
