export const TO_HOME = "홈으로 이동";
export const TO_CATEGORIES = "카테고리 정보로 이동";
export const TO_TAGS = "태그 사전으로 이동";

export const OPEN_GITHUB = "새 탭에서 깃헙 정보 열기";
export const OPEN_BUY_ME_A_COFFEE = "새 탭에서 후원하기";
export const OPEN_RSS = "새 탭에서 RSS 열기";

export const SEND_EMAIL = "이메일 보내기";
export const TOGGLE_THEME = "테마 변경";

export const SHOW_TAGS_BY_KOR = "한국어명 기준 정렬";
export const SHOW_TAGS_BY_ENG = "영어명 기준 정렬";

export const TO_TAG_BY_KOR = (tag) => `${tag}에 대한 글들 보러가기`;
export const TO_TAG_BY_ENG = (tag) => `Go to blog posts on ${tag}`;
