import styled from "styled-components";

export const FooterWrapper = styled.footer`
  margin-top: 30px;
  padding: 0 20px;

  min-width: ${({ theme }) => theme.widthSize.minimum};

  white-space: nowrap;

  ${({ theme }) => theme.preventUserSelect};
`;
