import * as React from "react";
import { TO_CATEGORIES, TO_HOME, TO_TAGS } from "../../../constants";
import { LinkContainer } from "./container";
import { NavigationLink } from "./link";
import { FooterTopWrapper } from "./wrapper";

export const FooterTop = () => {
  return (
    <FooterTopWrapper>
      <LinkContainer>
        <NavigationLink to="/" title={TO_HOME}>
          홈
        </NavigationLink>
      </LinkContainer>
      <LinkContainer>
        <NavigationLink to="/categories" title={TO_CATEGORIES}>
          카테고리
        </NavigationLink>
      </LinkContainer>
      <LinkContainer>
        <NavigationLink to="/tags" title={TO_TAGS}>
          태그
        </NavigationLink>
      </LinkContainer>
      {/* <LinkContainer>
        <NavigationLink to="/tag/info">정보</NavigationLink>
      </LinkContainer> */}
    </FooterTopWrapper>
  );
};
