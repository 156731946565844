import * as React from "react";
import styled from "styled-components";
import { Header } from "./header";
import { Main } from "./main";
import { Footer } from "./footer";
import { DisplayAds } from "../components/display-ad";

const Wrapper = styled.div`
  margin: auto;
  max-width: ${({ theme }) => theme.widthSize.maxScreenWidth};
`;

const Body = ({ children, toggleTheme, addDefaultMainAd }) => {
  return (
    <Wrapper>
      <Header toggleTheme={toggleTheme} />
      <Main>
        {addDefaultMainAd && (
          <div style={{ margin: "0 20px 30px 20px"}}>
            <DisplayAds dataAdSlot={"5449699468"} />
          </div>
        )}
        {children}
      </Main>
      <Footer />
    </Wrapper>
  );
};

export default Body;
