import React from "react";
import styled from "styled-components";
import { MD_HEADER_CLASS } from "../../../../constants";
import { toIdFormat } from "../../../../utils";
import { H4 } from "../../../common";
import { commonHeaderStyles } from "../common";

const ContentH4 = styled(H4)`
  ${commonHeaderStyles}
  font-size: ${({ theme }) => theme.fontSize.huge};
  padding: 10px 0;
`;

export const MarkDownH4 = ({ children }) => {
  return (
    <ContentH4 id={toIdFormat(children)} className={MD_HEADER_CLASS}>
      {children}
    </ContentH4>
  );
};
