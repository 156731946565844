import React from "react";
import { MDXProvider } from "@mdx-js/react"; // reference: https://www.gatsbyjs.com/docs/how-to/routing/customizing-components/
import { ThemeProvider } from "styled-components";
import { LIGHT } from "../constants";
import { useThemeToggle } from "../hooks";
import Body from "./body";
import {
  ResetStyle,
  GlobalStyle,
  shortcodes,
  lightTheme,
  darkTheme,
} from "../styles";

const Layout = ({ children, addDefaultMainAd }) => {
  const [themeMode, toggleTheme, componentMounted] = useThemeToggle();

  if (!componentMounted) return <></>;

  return (
    <ThemeProvider theme={themeMode === LIGHT ? lightTheme : darkTheme}>
      <MDXProvider components={shortcodes}>
        <ResetStyle />
        <GlobalStyle />
        <Body toggleTheme={toggleTheme} addDefaultMainAd={addDefaultMainAd}>{children}</Body>
      </MDXProvider>
    </ThemeProvider>
  );
};

export default Layout;
