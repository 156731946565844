import { css } from "styled-components";
import {
  TopFootNote,
  FootNoteSection,
  BottomFootNote,
  MarkdownAnchor,
  MarkDownH1,
  MarkDownH2,
  MarkDownH3,
  MarkDownH4,
  MarkDownItalic,
  MarkdownLink,
  MarkDownParagraph,
  MarkDownStrong,
  Bar,
  MarkdownUl,
  MarkdownOl,
  InfoSection,
  SubSection,
  ReferenceText,
  OfficialDoc,
} from "../components/blog/markdown";
import { device, MAX_SCREEN_WIDTH } from "../constants";

export const shortcodes = {
  Link: MarkdownLink,
  h1: MarkDownH1,
  h2: MarkDownH2,
  h3: MarkDownH3,
  h4: MarkDownH4,
  p: MarkDownParagraph,
  strong: MarkDownStrong,
  em: MarkDownItalic,
  a: MarkdownAnchor,
  ul: MarkdownUl,
  ol: MarkdownOl,
  ReferenceText,
  OfficialDoc,
  Bar,
  TopFootNote,
  BottomFootNote,
  FootNoteSection,
  SubSection,
  InfoSection,
};

const codeBlockWrapper = css`
  .gatsby-highlight {
    border: 1px solid black;
    background-color: black;
    border-radius: 0.3em;
    margin: 0 0.5em;
    margin-bottom: 20px;
    padding: 0 0.5em;
    overflow: hidden;
    color: white;

    @media ${device.maxScreenWidth} {
      max-width: ${MAX_SCREEN_WIDTH * 0.7 - 60}px;
    }
  }
`;

const codeLine = css`
  .gatsby-highlight pre[class*="language-"].line-numbers {
    padding: 0;
    padding-left: 2.5em;
    overflow: hidden;
    overflow-x: auto;
    background-color: inherit;
  }
`;

const highlightedCodeLine = css`
  .gatsby-highlight-code-line {
    background-color: rgb(11, 6, 61);
    color: white;
    display: block;
    margin-left: -0.5em;
    padding-left: 0.5em;
  }
`;

export const codeBlockStyle = css`
  ${codeBlockWrapper}
  ${codeLine}
  ${highlightedCodeLine}
  sup {
    vertical-align: super;
    font-size: smaller;
  }
  sub {
      vertical-align: sub;
      font-size: smaller;
  }
`;
