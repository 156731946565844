import { createGlobalStyle } from "styled-components";
import { codeBlockStyle } from "./markdown-style";

// 핵심: transition 적용시, theme값을 직접 사용하지 않는 element들의 경우 theme 변경시 느리게 적용됨

export const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    ${({ theme }) => theme.baseStyles()};
  }
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family:  ${({ theme }) => theme.font.main};
    font-size: 16px;
    color: ${({ theme }) => theme.colors.textColor};
  }
  body {
    line-height: 1.5;
    letter-spacing: 0;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
  a {
    text-decoration: none; 
    color:inherit;
  }
  ${codeBlockStyle}
`;
