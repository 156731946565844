import { css } from "styled-components";

export const InlineCodeStyle = css`
  font-size: ${({ theme }) => theme.fontSize.xs};
  line-height: inherit;
  font-family: ${({ theme }) => theme.font.monospace};
  background-color: ${({ theme }) => theme.colors.grayBoxTranslucent};
  border-radius: 5px;
  padding: 5px;
  margin: 0 2px;
  color: ${({ theme }) => theme.colors.textColor};
`;
