import * as React from "react";
import { FooterWrapper } from "./wrapper";
import { FooterBottom } from "./bottom";
import { FooterTop } from "./top";
import { DisplayAds } from "../display-ad";

export const Footer = () => {
  return (
    <FooterWrapper>
      <div style={{ margin: "0 20px"}}>
        <DisplayAds dataAdSlot={"8420721004"} />
      </div>
      <FooterTop />
      <FooterBottom />
    </FooterWrapper>
  );
};
