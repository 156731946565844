import styled from "styled-components";

export const IdNumBox = styled.div`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.lineHeight.normal};
  border-radius: 20%;
  padding: 5px 8px;
  background-color: ${({ theme }) => theme.colors.grayBorder};
`;
