import styled from "styled-components";
import { MarkDownParagraph } from "../text/p";

export const SectionWrapper = styled(MarkDownParagraph).attrs({ as: "div" })`
  display: flex;
  padding: 10px;
  margin-bottom: 20px;

  background-color: ${({ theme }) => theme.colors.markdownInfoSectionBox};
  border-radius: 10px;
`;
