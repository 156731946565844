import { useEffect, useState } from "react";
import { LIGHT, DARK, THEME } from "../constants";

export const setLocalStorageTheme = (themeMode) => {
  if (themeMode !== LIGHT && themeMode !== DARK) {
    throw new Error(
      "only light and dark mode available for setLocalStorageTheme"
    );
  }
  window.localStorage.setItem(THEME, themeMode);
};

export const useThemeToggle = () => {
  const [theme, setTheme] = useState(DARK);
  const [componentMounted, setComponentMounted] = useState(false);

  const setMode = (mode) => {
    setLocalStorageTheme(mode);
    setTheme(mode);
  };

  const toggleTheme = () => {
    if (theme === LIGHT) {
      setMode(DARK);
    } else {
      setMode(LIGHT);
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem(THEME);
    // if (
    //   window.matchMedia &&
    //   window.matchMedia("(prefers-color-scheme: light)").matches &&
    //   !localTheme
    // ) {
    //   setMode(LIGHT);
    // } else if (localTheme) {
    if (localTheme) {
      setTheme(localTheme);
    } else {
      setMode(DARK);
    }

    setComponentMounted(true);
  }, []);

  return [theme, toggleTheme, componentMounted];
};
