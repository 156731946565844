import styled from "styled-components";
import { LIGHT } from "../../constants";

export const ThemeToggleButton = styled.button`
  display: none;

  @media ${({ theme }) => theme.device.minimum} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  position: relative;

  border: 0.2rem solid black;
  border-radius: 1rem;
  padding: 0.2rem;

  width: 4rem;
  height: 2rem;

  background-color: black;
  font-size: 0.5rem;
  cursor: pointer;

  div {
    height: auto;
    width: auto;

    &:nth-child(3) {
      position: absolute;
      top: -0.1rem;
      left: -0.1rem;

      height: 1.8rem;
      width: 1.8rem;

      background-color: white;
      border-radius: 50%;

      ${({ theme }) => theme.baseStyles(["transform"])};

      transform: ${({ theme }) =>
        theme.name === LIGHT ? "translateX(0px)" : "translateX(2rem)"};
    }
  }

  &:focus {
    & > div:nth-child(3) {
      top: -0.2rem;
      left: -0.2rem;

      height: 2rem;
      width: 2rem;

      border: 0.2rem solid ${({ theme }) => theme.colors.highlightColor};
    }
  }
`;
