import tagYAMLData from "../../data/tags.yaml";

export const getValidTagInfoDictionary = () => {
  const validDict = {};

  tagYAMLData.forEach((info) => {
    validDict[info.name] = {
      ko: info.ko,
      en: info.en,
      slug: info.slug,
      ignore: info.ignore !== undefined ? info.ignore : false,
    };
  });

  return validDict;
};

export const getNamesByTagSlug = (slug) => {
  for (let idx = 0; idx < tagYAMLData.length; idx++) {
    if (tagYAMLData[idx].slug !== slug) continue;
    return {
      ko: tagYAMLData[idx].ko,
      en: tagYAMLData[idx].en,
    };
  }
};
