import React from "react";
import { InfoIconSolid } from "../../../common";
import { ContentContainer } from "./content-container";
import { InfoIconBox } from "./icon-box";
import { SectionWrapper } from "./wrapper";

export const InfoSection = ({ children }) => {
  return (
    <SectionWrapper>
      <InfoIconBox>
        <InfoIconSolid size={40} />
      </InfoIconBox>
      <ContentContainer>{children}</ContentContainer>
    </SectionWrapper>
  );
};
