import styled from "styled-components";
import { Paragraph } from "../../../common";
import { css } from "styled-components";

export const strongStyle = css`
  color: ${({ theme }) => theme.colors.markdownStrongTagHighlight};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  & > a {
    color: ${({ theme }) => theme.colors.markdownStrongTagHighlight};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export const MarkDownStrong = styled(Paragraph).attrs({ as: "strong" })`
  ${strongStyle}
`;
