export const BLOG_POST_SECTION_CLASS = `blog_post_section_class`;

export const MD_HEADER_CLASS = `md_header_class`;
export const HEADER_CLASS_POSTFIX = `-header`;
export const HEADER_CLASS_POSTFIX_LENGTH = 7;
export const HEADER_HEIGHT = 100;
export const HEADER_HEIGHT_COVERAGE = HEADER_HEIGHT + 5;

export const heightConstants = {
  HEADER_HEIGHT,
  HEADER_HEIGHT_COVERAGE,
};
