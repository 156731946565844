import styled from "styled-components";

export const CopyrightContainer = styled.div`
  white-space: nowrap;

  color: ${({ theme }) => theme.colors.grayThick};
  font-size: ${({ theme }) => theme.fontSize.lg};

  margin: 0 5px 10px 0;
`;
