import * as React from "react";
import { ThemeToggleButton } from "./theme-toggle-button";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { H1 } from "../common";
import { HeaderContent } from "./content";
import { HeaderWrapper } from "./wrapper";
import styled from "styled-components";
import { TOGGLE_THEME, TO_HOME } from "../../constants";

const ToHomeText = styled(H1)`
  font-family: ${({ theme }) => theme.font.monospace};
`;

export const Header = ({ toggleTheme }) => {
  return (
    <HeaderWrapper>
      <HeaderContent>
        <Link to="/" title={TO_HOME}>
          <ToHomeText>Jinwoo's Blog</ToHomeText>
        </Link>
        <div></div>
        <ThemeToggleButton onClick={toggleTheme} title={TOGGLE_THEME}>
          <StaticImage src="./assets/dark-mode.png" alt="dark mode icon" />
          <StaticImage src="./assets/light-mode.png" alt="light mode icon" />
          <div></div>
        </ThemeToggleButton>
      </HeaderContent>
    </HeaderWrapper>
  );
};
