import React from "react";
import styled from "styled-components";
import { Paragraph } from "../../../common";
import { css } from "styled-components";

export const barStyle = css`
  margin: 0px;
  padding: 0px;
  position: relative;

  &:after {
    ${({ theme }) => theme.baseStyles()};
    position: absolute;
    top: 3px;
    left: 0px;
    content: attr(value);
    color: ${({ theme }) => theme.colors.backgroundColor};
    border-top: 1.8px solid ${({ theme }) => theme.colors.textColor};
    z-index: -1;
  }
`;

const SBar = styled(Paragraph).attrs({ as: "span" })`
  ${barStyle}
`;

export const Bar = ({ children }) => {
  return <SBar value={children}>{children}</SBar>;
};
