// Reference: https://ogp.me/#no_vertical

import { getNamesByTagSlug } from "../../utils";

export const getOpenGraphData = (seoProps) => {
  const { title, description, imageUrl, pageUrl, article, author } = seoProps;

  const openGraphData = {
    title,
    description: description,
    url: pageUrl,
    type: article ? "article" : "website",
    images: [
      {
        url: imageUrl,
        width: 800,
        height: 400,
        alt: article?.mainImageAlt || "bugoverdose site main image",
      },
    ],
  };
  if (article) {
    openGraphData["article"] = {
      publishedTime: article.published_time,
      modifiedTime: article.modified_time,
      section: article.category,
      authors: [author],
      tags: [
        ...new Set(
          article.tags.reduce((allTagList, cur) => {
            const tagNames = getNamesByTagSlug(cur);
            allTagList.push(tagNames.ko);
            allTagList.push(tagNames.en);
            return allTagList;
          }, [])
        ),
      ],
    };
  }
  return openGraphData;
};
