import styled from "styled-components";

export const FooterTopWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;

  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.device.desktop} {
    justify-content: flex-end;
  }
`;
