import { useEffect } from "react";
import { useState } from "react";
import {
  BLOG_POST_SECTION_CLASS,
  HEADER_HEIGHT_COVERAGE,
} from "../../constants";

export const scrollToByElementId = (targetId) => {
  const targetElement = document.querySelector(targetId);
  if (!targetElement) return;

  window.scrollTo({
    top: targetElement.offsetTop - HEADER_HEIGHT_COVERAGE - 5,
    behavior: "smooth",
  });
};

export const calculateHeaderPositionY = (id) => {
  const headerElement = document.getElementById(id);
  if (!headerElement) return null;

  return headerElement.offsetTop - HEADER_HEIGHT_COVERAGE;
};

export const scrollToHeaderElement = (id) => {
  const headerPosition = calculateHeaderPositionY(id);
  if (headerPosition === null) return;

  window.scrollTo({
    top: headerPosition,
    behavior: "smooth",
  });
};

export const useWindowPositionY = () => {
  const [windowPositionY, setWindowPositionY] = useState(window.scrollY);

  useEffect(() => {
    const handleScroll = () => {
      setWindowPositionY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return windowPositionY;
};

export const getOutsideBlogPostPositionY = () => {
  const post = document.querySelector(`.${BLOG_POST_SECTION_CLASS}`);
  return post.offsetTop + post.offsetHeight;
};
