import styled from "styled-components";

export const Svg = styled.svg`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
