export const getRootPathFromPagination = (pathname, currentPage) => {
  const redundantLength = `/page/${currentPage}`.length;
  let trimedPath = pathname.slice(0, pathname.length - redundantLength);

  while (trimedPath.endsWith("/")) {
    trimedPath = trimedPath.slice(0, -1);
  }
  while (trimedPath.startsWith("/")) {
    trimedPath = trimedPath.slice(1);
  }

  return `/${trimedPath}`;
};

export const getTargetPaginationPath = (rootPath, targetPage) => {
  if (targetPage === 1) return rootPath;

  const pagination = `${rootPath}/page/${targetPage}`;
  return pagination.replaceAll("//", "/");
};
