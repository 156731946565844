import styled from "styled-components";

export const CopyrightWrapper = styled.div`
  margin-top: 10px;

  display: flex;
  flex-wrap: wrap;

  justify-content: center;

  @media ${({ theme }) => theme.device.desktop} {
    justify-content: flex-start;
  }
`;
