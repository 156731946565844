import {
  HEADER_CLASS_POSTFIX,
  HEADER_CLASS_POSTFIX_LENGTH,
} from "../../constants";

export const toIdFormat = (stringInput) => {
  return (
    stringInput
      .replace(/ /g, "-")
      .replace(".", "")
      .replace(",", "")
      .replace(":", "")
      .replace(";", "")
      .replace("=", "")
      .replace("?", "")
      .replace("/", "")
      .toLowerCase() + HEADER_CLASS_POSTFIX
  );
};

export const getIdQueryFormatFromUrlHash = () => {
  const hash = decodeURI(window.location.hash);
  return hash + HEADER_CLASS_POSTFIX;
};

export const idToUrlHashFormat = (elementId) =>
  elementId.substring(0, elementId.length - HEADER_CLASS_POSTFIX_LENGTH);
