import styled from "styled-components";

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 99;
  height: ${({ theme }) => theme.heightSize.HEADER_HEIGHT}px;

  width: 100%;

  color: ${({ theme }) => theme.colors.textColor};
  background-color: ${({ theme }) => theme.colors.backgroundColorTranslucent};

  box-shadow: ${({ theme }) => theme.colors.boxShadow};

  ${({ theme }) => theme.preventUserSelect};
`;
