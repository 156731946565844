import styled from "styled-components";
import { strongStyle } from "./strong";
import { emStyle } from "./em";
import { Paragraph } from "../../../common";
import { InlineCodeStyle } from "./inline-code";

export const MarkDownParagraph = styled(Paragraph)`
  /* default Paragraph style for p tag */

  strong {
    ${strongStyle}
  }

  em {
    ${emStyle}
  }

  /* inline backticks, not triple backticks */
  & > code {
    ${InlineCodeStyle}
  }
`;
