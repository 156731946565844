import { useEffect } from "react";
import { getIdQueryFormatFromUrlHash, scrollToHeaderElement } from "../utils";

export const useHashForInitialPosition = () => {
  useEffect(() => {
    let timeoutID;

    const scrollIfHashExists = () => {
      if (window.location.hash === "") return;

      const hashTargetId = getIdQueryFormatFromUrlHash();

      timeoutID = window.setTimeout(
        () => scrollToHeaderElement(hashTargetId),
        50
      );
    };

    scrollIfHashExists();

    window.addEventListener("hashchange", scrollIfHashExists);
    return () => {
      window.removeEventListener("hashchange", scrollIfHashExists);
      if (timeoutID) clearTimeout(timeoutID);
    };
  }, []);
};
