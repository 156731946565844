import { css } from "styled-components";

export const transitionStyles = (style) => css`
  -o-transition: ${style};
  -moz-transition: ${style};
  -ms-transition: ${style};
  -webkit-transition: ${style};
  transition: ${style};
`;

export const preventUserSelect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
`;

const transitionProps = (
  properties = ["color", "background-color", "border-color"]
) => "".concat(properties.map((property) => `${property} .2s ease `));

export const baseStyles = (transitionTargets) => css`
  box-sizing: border-box;
  ${transitionStyles(transitionProps(transitionTargets))}
`;
