export const LANGUAGE = {
  KO: "ko",
  EN: "en",
};

export const DICTIONARY_BREAKPOINTS = {
  ko: {
    breakPoints: ["가", "바", "차"], // ㄱ,ㅏ 등 단일 자음/모음은 없다고 간주
    caption: ["A~Z", "ㄱ~ㅁ", "ㅂ~ㅈ", "ㅊ~ㅎ", "기타"],
  },
  en: {
    breakPoints: ["A", "F", "K", "O", "U"], // 소문자로 시작하는 경우는 없다고 간주
    caption: ["#", "A~E", "F~J", "K~N", "O~T", "U~Z", "Others"],
  },
};
