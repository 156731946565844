import styled from "styled-components";

export const ExternalLinksWrapper = styled.div`
  color: ${({ theme }) => theme.colors.textColor};
  font-size: ${({ theme }) => theme.fontSize.lg};

  display: flex;
  justify-content: center;

  margin-bottom: 10px;

  @media ${({ theme }) => theme.device.desktop} {
    justify-content: flex-end;
    margin-bottom: 0px;
  }
`;
