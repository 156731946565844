import { useEffect } from "react";

export const useAddReferenceOnCopy = (title, pageUrl) => {
  useEffect(() => {
    const addReferenceOnCopy = () => {
      const selection = window.getSelection();
      const pagelink = `<br /><br />출처: [${title}](<a href="${pageUrl}">${pageUrl}</a>)<br />Copyright &copy; Jinwoo's Blog`;

      const copytext = selection + pagelink;

      const newdiv = document.createElement("div");
      newdiv.style.position = "absolute";
      newdiv.style.left = "-99999px";
      newdiv.style.top = "0px";
      document.body.appendChild(newdiv);
      newdiv.innerHTML = copytext;
      selection.selectAllChildren(newdiv);

      window.setTimeout(() => document.body.removeChild(newdiv), 10);
    };

    document.addEventListener("copy", addReferenceOnCopy);
    return () => document.addEventListener("copy", addReferenceOnCopy);
  }, [title, pageUrl]);
};
