import { LANGUAGE } from "../constants";

export const isKo = (languageInput) => {
  if (languageInput === LANGUAGE.KO) {
    return true;
  }
  if (languageInput === LANGUAGE.EN) {
    return false;
  }
  throw new Error(
    `Only ${Object.values(LANGUAGE).join(" & ")} can be a language state`
  );
};

export const isEn = (languageInput) => {
  if (languageInput === LANGUAGE.EN) {
    return true;
  }
  if (languageInput === LANGUAGE.KO) {
    return false;
  }
  throw new Error(
    `Only ${Object.values(LANGUAGE).join(" & ")} can be a language state`
  );
};

export const isValidLanguageParam = (locationSearchInput) => {
  if (!locationSearchInput.startsWith("?sortBy=")) return false;

  const languageInput = locationSearchInput.slice(8);
  if (languageInput.length !== 2) return false;
  if (!Object.values(LANGUAGE).includes(languageInput)) return false;

  return true;
};
