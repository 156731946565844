const MINIMUM = 320;
const MOBILE = 440;
const MEDIUM = 555;
const DESKTOP = 900;
export const MAX_SCREEN_WIDTH = 1200;
const HOME_NAV_WIDTH = 300;
const RECENT_POSTS_LIST_WIDTH = 400;

export const widthSize = {
  minimum: `${MINIMUM}px`,
  mobile: `${MOBILE}px`,
  medium: `${MEDIUM}px`,
  desktop: `${DESKTOP}px`,
  maxScreenWidth: `${MAX_SCREEN_WIDTH}px`,

  MAX_SCREEN_WIDTH,
  HOME_NAV_WIDTH,
  RECENT_POSTS_LIST_WIDTH,
};

export const device = {
  minimum: `(min-width: ${widthSize.minimum})`,
  mobile: `(min-width: ${widthSize.mobile})`,
  medium: `(min-width: ${widthSize.medium})`,
  desktop: `(min-width: ${widthSize.desktop})`,
  maxScreenWidth: `(min-width: ${widthSize.maxScreenWidth})`,
};
