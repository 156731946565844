import styled from "styled-components";

export const FooterBottomWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.grayBorder};
  padding: 30px 0 60px 0;

  display: flex;
  flex-direction: column-reverse;

  @media ${({ theme }) => theme.device.desktop} {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
  }
`;
