import { css } from "styled-components";
import { SParagraph } from "./index";
import { CommonTextStyle } from "./common";

export const ListWrapperStyle = css`
  ${SParagraph}
  margin-left: 25px;
`;

export const ListStyle = css`
  ${CommonTextStyle}
  color: ${({ theme }) => theme.colors.textColor};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
