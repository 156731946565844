import categoryYAMLData from "../data/categories.yaml";

export const categoryData = categoryYAMLData.slice(1);

export const getCategoryNames = () =>
  categoryYAMLData
    .map((category) => category.name)
    .filter((categoryName) => categoryName !== "all");

export const getCategoryInfoDictionary = () => {
  const newDictionary = {};
  categoryYAMLData.forEach((info) => {
    newDictionary[info.name] = {
      ko: info.ko,
      en: info.en,
      slug: info.slug,
    };
  });
  return newDictionary;
};

export const getCategoryInfoWithCount = (categoryList) => {
  const categoryNames = getCategoryNames(categoryYAMLData);
  const categoryInfoDictionary = getCategoryInfoDictionary(categoryYAMLData);

  const categoryInfoList = [];

  categoryInfoList.push({
    ...categoryInfoDictionary["all"],
    count: categoryList.length,
  });
  categoryNames.forEach((categoryName) =>
    categoryInfoList.push({
      ...categoryInfoDictionary[categoryName],
      count: categoryList.reduce((n, cur) => n + (cur === categoryName), 0),
    })
  );

  return categoryInfoList;
};
